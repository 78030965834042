<template>
  <div class="ui-radial">
    <svg :viewBox="`0 0 ${width} ${height}`">
      <circle
        v-for="(circle,i) in svgCircles"
        :key="circle.id"
        v-bind="circle.attrs"
        @mouseenter="$emit('slice-enter', slices[i])"
        @mouseleave="$emit('slice-leave', slices[i])"
      />
    </svg>
  </div>
</template>

<script>
// https://codepen.io/xgad/post/svg-radial-progress-meters
export default {
  name: 'UiRadial',

  props: {
    slices: {
      type: Array,
      required: false,
      default: () => [],
    },

    width: {
      type: [Number, String],
      required: false,
      default: 240,
    },

    height: {
      type: [Number, String],
      required: false,
      default: 240,
    },
  },

  computed: {
    svgCircles() {
      return this.slices.map((slice, i) => {
        let r_start = parseFloat(slice.r_start);
        let r_end = parseFloat(slice.r_end);

        let strokeWidth = r_end - r_start;
        let radius = r_start + strokeWidth / 2;
        let circumference = 2 * Math.PI * radius;

        let start = parseFloat(slice.start);
        let end = parseFloat(slice.end);
        let length = end - start; // longitud radial, en grados (i.e. longitud 90 es un cuarto de circulo)

        let dashOffset = circumference + (length * circumference) / 360;

        let rotate = start * -1;
        let color = slice.color || '#cccccc';

        return {
          id: slice.id || i,
          attrs: {
            class: ['radial__slice', slice.class],
            fill: 'none',
            cx: this.width / 2,
            cy: this.height / 2,
            r: radius,
            stroke: color,
            'stroke-width': strokeWidth,
            'stroke-dasharray': circumference,
            'stroke-dashoffset': dashOffset + 0.5,
            style: {
              ...slice.style,

              '--radial-slice-color': color,
              transform: `rotate(${rotate}deg)`,
              transformOrigin: `${this.width / 2}px ${this.height / 2}px`,
            },
          },
        };
      });
    },
  },
};
</script>